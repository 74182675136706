<template>
  <v-main>

  </v-main>
</template>

<script>
export default {
  name: 'DispatchHome',
  data() {
    return {}
  },
  methods: {},
  mounted() {
    console.log("test")
    this.$router.push("/dispatch")
  }
}
</script>

<style scoped>

</style>
