import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios';


Vue.use(Vuex)

const showDialogInternal = function(dialog, {type, text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback}) {
    dialog.visible = true
    dialog.text = text
    dialog.type = type
    dialog.callback = callback
    dialog.buttonText = buttonText
    dialog.cancelButtonText = cancelButtonText
    dialog.cancelButtonColor = cancelButtonColor
    dialog.cancelButtonCallback = cancelButtonCallback
    if (dialog.timeout) {
        clearTimeout(dialog.timeout)
    }

    if (time && time > 0) {
        dialog.timeout = setTimeout(function () {
            dialog.visible = false
            dialog.text = ""
        }, time)
    }
}

const hideDialogInternal = function (dialog) {
    dialog.visible = false
    dialog.text = ""
}


export default new Vuex.Store({
    state: {
        loading: false,
        alertDialog: {
            visible: false,
            text: "",
            type: "",
            timeout: null
        },
        confirmDialog: {
            visible: false,
            text: "",
            buttonText: "",
            cancelButtonText: null,
            cancelButtonColor: null,
            type: "error",
            timeout: null,
            callback: null
        },
        order: {},
        printerName: ""
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setOrder(state, order) {
            state.order = order
        },
        showAlertDialog(state, {type, text, time}) {
            showDialogInternal(state.alertDialog, {type, text, time})
        },
        hideAlertDialog(state) {
            hideDialogInternal(state.alertDialog)
        },
        showConfirmDialog(state, {type, text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback}) {
            showDialogInternal(state.confirmDialog, {type, text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback})
        },
        hideConfirmDialog(state) {
            hideDialogInternal(state.confirmDialog)
        },
        setPrinterName(state, printerName) {
            state.printerName = printerName
        }
    },
    actions: {
        login({commit}, {username, password}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                const request = {
                    username: username,
                    password: password
                }

                axios
                    .post("/api/login", request)
                    .then(function (response) {
                        let authenticationResponse = response.data;
                        localStorage.setItem("lang.authentication.jwtToken", authenticationResponse.jwtToken)
                        resolve()
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Fehler beim Login", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false));
            })
        },
        showError({commit}, {text, time}) {
            const type = 'error'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showErrorConfirm({commit}, {text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback}) {
            const type = 'error'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {type, text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback})
        },
        showInfo({commit}, {text, time}) {
            const type = 'info'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showInfoConfirm({commit}, {text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback}) {
            const type = 'info'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {type, text, buttonText, time, callback, cancelButtonText, cancelButtonColor, cancelButtonCallback})
        },
        loadOrder({commit}, billBeeOrderId) {
            commit('setLoading', true)
            console.log("Loading order " + billBeeOrderId)
            const self = this
            return new Promise((resolve, reject) => {
                axios.get("/api/dispatch/" + billBeeOrderId)
                    .then(function (response) {
                        const orderResponse = response.data
                        if (orderResponse.code === "Ok") {
                            const order = orderResponse.data

                            // patch dispatched quantity
                            for (const i in order.orderItems) {
                                let orderItem = order.orderItems[i];
                                if (orderItem.product.ean) {
                                    orderItem.dispatchedQuantity = 0
                                } else {
                                    orderItem.dispatchedQuantity = orderItem.quantity
                                }
                                self.dispatch("loadDetails", orderItem)
                            }

                            commit('setOrder', order)
                            resolve(order)
                        } else if (orderResponse.code === "OrderNotFound") {
                            self.dispatch('showError', {text: "Order nicht gefunden", time: 3000})
                            reject()
                        } else if (orderResponse.code === "OrderAlreadyDispatched") {
                            self.dispatch('showError', {text: "Bestellung wurde bereits versendet.", time: 3000})
                            reject()
                        } else if (orderResponse.code === "OrderAlreadyAtReclamation") {
                            self.dispatch('showError', {text: "Versandadresse noch nicht validiert.", time: 3000})
                            reject()
                        } else if (orderResponse.code === "OrderAddressValidationErrors") {
                            self.dispatch('showError', {text: "Versandadresse ist nicht validiert. Bitte Bestellung parken.", time: 3000})
                            reject()
                        } else if (orderResponse.code === "OrderItemsValidationErrors") {
                            self.dispatch('showError', {text: "Zolldaten fehlen. Bitte Bestellung parken.", time: 3000})
                            reject()
                        } else if (orderResponse.code === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadDetails({commit}, orderItem) {
            commit('setLoading', false)
            const product = orderItem.product
            axios
                .get("/api/products/" + product.ean + "/lite")
                .then(function (res) {
                    const productLiteResponse = res.data
                    if (productLiteResponse !== null && productLiteResponse.code === "ProductFoundInBillbee") {
                        const productLite = productLiteResponse.data;
                        product.extraDataLoaded = true
                        product.images = productLite.images
                        product.color = productLite.color
                        product.size = productLite.size
                        product.stock = productLite.stock
                        product.category1 = productLite.category1
                        product.returnIndication = productLite.returnIndication
                    }
                })
        },
        createLabel({commit}) {
            commit('setLoading', true)
            const self = this
            console.log("posting", self.state.order)
            return new Promise((resolve, reject) => {
                let url = '/api/dispatch?printerName=' + self.state.printerName;
                axios.post(url, self.state.order)
                    .then(function (response) {
                        const createLabelResponse = response.data
                        if (createLabelResponse.code === "OrderDispatchedSuccessfully") {
                            self.state.order.shipmentDetails.length = 0 // clear array

                            const newOrder = createLabelResponse.data
                            for (const i in newOrder.shipmentDetails) {
                                const shipmentDetail = newOrder.shipmentDetails[i]
                                self.state.order.shipmentDetails.push(shipmentDetail)
                            }

                            resolve()
                        } else if (createLabelResponse.code === "TechnicalError") {
                            reject()
                        }
                    })
                    .catch(function () {
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        confirmDispatch({commit}) {
            commit('setLoading', true)
            const self = this
            console.log(self.state.order)
            return new Promise((resolve, reject) => {
                axios.post('/api/dispatch/' + self.state.order.billBeeOrderId + '/confirmDispatch')
                    .then(function (response) {
                        const dispatchResponse = response.data
                        if (dispatchResponse.code === "Ok") {
                            resolve()
                        } else if (dispatchResponse === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        requestAdditionalLabel({commit}) {
            commit('setLoading', true)
            const self = this
            return new Promise((resolve, reject) => {
                let url = '/api/dispatch/additionalLabel?printerName=' + self.state.printerName;
                axios.post(url, self.state.order)
                    .then(function (response) {
                        const createLabelResponse = response.data
                        if (createLabelResponse.code === "OrderDispatchedSuccessfully") {
                            self.state.order.shipmentDetails.length = 0 // clear array

                            const newOrder = createLabelResponse.data
                            for (const i in newOrder.shipmentDetails) {
                                const shipmentDetail = newOrder.shipmentDetails[i]
                                self.state.order.shipmentDetails.push(shipmentDetail)
                            }

                            resolve()
                        } else if (createLabelResponse.code === "TechnicalError") {
                            reject()
                        }
                    })
                    .catch(function () {
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        }
    },
    modules: {}
})