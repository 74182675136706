<template>
  <v-main>
    <v-container class="pa-8" fluid>
      <v-row class="mb-1 pl-3">
        <v-col cols="12">
          <div class="text-h4 transition-swing" v-text="'Label-Drucker auswählen/scannen'"></div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-form ref="printerNameEntry" v-model="printerEntryValid" v-on:submit.prevent="noop">
          <v-col class="flex-grow-1">
            <v-text-field
                v-model="printerName"
                ref="printerName"
                clearable
                dense
                outlined
                inputmode="numeric"
                :label="'Label-Drucker Name'"
                :rules="printerNameRules"
                @keyup.enter="printerScanned(printerName)"></v-text-field>
          </v-col>
        </v-form>
        <v-col>
          <v-btn color="secondary" tile :disabled="!printerEntryValid" @click="printerScanned(printerName)">OK
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-1 pl-3">
        <v-col cols="12">
          <div class="text-h4 transition-swing" v-text="'Billbee OrderID / Bestellnummer eingeben/scannen'"></div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-form ref="billBeeOrderIdEntry" v-model="valid" v-on:submit.prevent="noop">
          <v-col class="flex-grow-1">
            <v-text-field
                v-model="billBeeOrderId"
                ref="billBeeOrderId"
                clearable
                dense
                outlined
                inputmode="numeric"
                :label="'Billbee OrderID / Bestellnummer'"
                :rules="entryRules"
                @keyup.enter="orderScanned(billBeeOrderId)"></v-text-field>
          </v-col>
        </v-form>
        <v-col>
          <v-btn color="secondary" tile :disabled="!valid" @click="orderScanned(billBeeOrderId)">Suchen</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>


import util from "@/util/util";

export default {
  name: "OrderNumberEntry",
  components: {},
  data() {
    return {
      billBeeOrderId: "",
      printerName: "",
      valid: false,
      printerEntryValid: false,
      entryRules: [
        v => !!v || "Required",
      ],
      printerNameRules: [
        v => !!v || "Required",
        v => util.validPrinterNames.includes(v) || "Ungültiger Label-Drucker"
      ]
    }
  },
  mounted() {
    let storedPrinterName = localStorage.getItem("lang.dispatch.printerName");
    if (storedPrinterName) {
      this.$refs["billBeeOrderId"].focus();
      this.printerName = storedPrinterName
      this.$store.commit("setPrinterName", storedPrinterName)
    } else {
      this.$refs["printerName"].focus();
    }
  },
  computed: {},
  methods: {
    orderScanned(billBeeOrderId) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("Order: " + billBeeOrderId)
      const self = this
      this.$store.dispatch('loadOrder', billBeeOrderId).then(function () {
        self.$router.push("/dispatch/" + billBeeOrderId)
      })
    },
    printerScanned(printerName) {
      if (this.printerEntryValid) {
        this.$store.commit("setPrinterName", printerName)
        localStorage.setItem("lang.dispatch.printerName", printerName);
        this.$refs["billBeeOrderId"].focus();
      }
    },
    noop() {

    }
  }
}
</script>

<style scoped>

</style>