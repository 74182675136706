<template>
  <v-row class="ml-3 mt-5">
    <v-col sm="12" md="12" lg="8" xl="8">
      <v-data-table
          disable-pagination
          :hide-default-footer="true"
          no-data-text="Keine Artikel"
          :headers="headers"
          :items="orderItems"
          class="elevation-1"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div>
            <v-scroll-x-transition>
              <v-icon :color="getColor(item)" v-if="showIcon(item)">{{getIcon(item)}}</v-icon>
            </v-scroll-x-transition>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <div>
            <span :class="item.quantity > 1 ? 'counter-red' : 'counter'"> {{ item.quantity }} </span>
          </div>
        </template>
        <template v-slot:[`item.scanned`]="{ item }">
          <div>
            <span class="counter">{{ item.scanned }} </span>
          </div>
        </template>

        <template v-slot:[`item.photo`]="{ item }">
          <div>
            <v-img
                v-if="item.photo"
                width="100"
                height="100"
                contain
                :src="item.photo"></v-img>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ArticleTable",
  data() {
    return {
      headers: [
        {
          text: "Bestellt",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Gescannt",
          sortable: false,
          value: "scanned"
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },{
          text: "Bild",
          sortable: false,
          value: "photo"
        },
        {
          text: "EAN",
          sortable: false,
          value: "ean"
        },
        {
          text: "Bezeichnung",
          sortable: false,
          value: "title"
        },
      ],
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderItems() {
      let result = []
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];
        result.push({
          quantity: orderItem.quantity,
          scanned: orderItem.dispatchedQuantity,
          status: "x",
          ean: orderItem.product.ean,
          title: orderItem.product.title,
          photo: this.getDefaultThumbUrl(orderItem.product.images)
        })
      }
      return result
    },
  },
  methods: {
    getColor(item) {
      if (item.scanned < item.quantity) {
        return "secondary"
      } else if (item.scanned === item.quantity) {
        return "green"
      }
      return "red"
    },
    getIcon(item) {
      if (item.scanned < item.quantity) {
        return "mdi-check-circle"
      } else if (item.scanned === item.quantity) {
        return "mdi-check-circle"
      }
      return "mdi-alert-circle"
    },
    showIcon(item) {
      if (item.scanned === item.quantity
          || (item.quantity > 1 && item.scanned > 0)) {
        return true
      }
      return false
    },
    getDefaultThumbUrl(images) {
      let defaultImage = images.find(i => i.default);
      if (!defaultImage) return null
      return defaultImage.url
    }
  }
}
</script>

<style scoped>
  .counter {
    font-size: 150%;
  }
  .counter-red {
    font-size: 150%;
    color: #ff5252;
    font-weight: bold;
  }
</style>