<template>
  <v-app>
    <v-app-bar
        app
        color="white"
        dark
    >
      <v-btn v-if="backArrowNeeded" color="primary" icon @click="back">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <router-link to="/">
        <v-img contain
               alt="Logo"
               :src="require('./assets/logo_langon.png')"
               :transition="'scale-transition'"
               max-height="130"
               max-width="180"
        />
      </router-link>
      <div class="text-h4 mt-1 ml-2 pageTitle font-weight-bold" v-text="'Versand'"></div>

    </v-app-bar>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <AlertDialog/>

    <ConfirmDialog/>

    <router-view></router-view>
  </v-app>
</template>

<script>
import AlertDialog from "@/components/AlertDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: 'App',
  components: {
    ConfirmDialog,
    AlertDialog
  },

  data: () => ({
  }),
  computed: {
    loadingOverlay: {
      get() {
        return this.$store.state.loading
      },
      set(value) {
        this.$store.commit("setLoading", value)
      }
    },
    backArrowNeeded() {
      console.log(this.$route.path)
      if (this.$route.path === "/home") return false
      if (this.$route.path === "/login") return false
      return true
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.pageTitle {
  color: darkgrey;
  font-weight: bold;
}
</style>