<template>
  <v-main class="mt-10 mr-5">
    <v-row class="ml-5">
      <div class="text-h4" v-text="order.orderNumber"></div>
    </v-row>
    <v-row class="ml-5">
      <v-chip small color="primary">{{ order.sellingPlatform }}</v-chip>
    </v-row>

    <ArticleTable/>
    <v-row class="ml-3 mt-5">
      <v-col xs="12" sm="6" md="6" lg="4" xl="4">
        <v-row v-if="order.shippingAddress">
          <v-col>
            <v-card>
              <v-card-title>Versand an</v-card-title>
              <v-card-text>
                <div v-if="order.shippingAddress.company">{{ order.shippingAddress.company }}</div>
                <div v-if="fullName">{{ fullName }}</div>
                <div v-if="order.shippingAddress.nameAdditional">{{ order.shippingAddress.nameAdditional }}</div>
                <div v-if="order.shippingAddress.streetAdditional">{{ order.shippingAddress.streetAdditional }}</div>
                <div v-if="streetAndHouseNumber">{{ streetAndHouseNumber }}</div>
                <div v-if="zipAndCity">{{ zipAndCity }}</div>
                <div v-if="order.shippingAddress.country">{{ order.shippingAddress.country }}</div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="orderDispatchComplete">
          <v-col>
            <v-card>
              <v-card-title>
                <div class="primary--text">Versandlabel scannen</div>
              </v-card-title>
              <v-row class="ml-2 mr-2 mt-1">
                <v-col class="col-auto">
                  <v-form ref="trackingNumberEntry" v-model="valid" v-on:submit.prevent="noop">
                    <v-text-field
                        v-model="trackingNumber"
                        ref="trackingNumber"
                        clearable
                        dense
                        outlined
                        inputmode="numeric"
                        :label="'Tracking Nr.'"
                        :rules="entryRules"
                        @keyup.enter="trackingNumberEntered(trackingNumber)"></v-text-field>
                  </v-form>
                </v-col>
                <v-col>
                  <v-btn color="secondary"
                         tile
                         :disabled="!valid"
                         @click="trackingNumberEntered(trackingNumber)"><span class="ma-2 text-wrap"
                                                                              style="width: min-content; margin: auto;">Bestellung
                  versendet</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="shippingInformation" cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-row v-for="(shippingInfo, idx)  in shippingInformation" :key="idx">
          <v-col>
            <v-card>
              <v-card-title>
                {{ 'Versandinformation Paket ' + (idx + 1) }}
                <v-scroll-x-transition>
                  <v-icon color="green" v-if="validatedTrackings.includes(shippingInfo.shippingId)">mdi-check-circle</v-icon>
                </v-scroll-x-transition>
              </v-card-title>
              <v-card-text>
                <div v-if="shippingInfo.shipper">{{ 'Versandleister: ' + shippingInfo.shipper }}</div>
                <div v-if="shippingInfo.shippingId">{{ 'TrackingNr: ' + shippingInfo.shippingId }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="12" lg="12" xl="8">
            <v-btn
                width="300"
                color="lightgray"
                block
                tile
                @click="requestAdditionalLabel">
              Zusätzliches Versandlabel erstellen
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DHLWarning v-if="shouldShowDHLWarning"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import ArticleTable from "@/components/ArticleTable";
import DHLWarning from "@/components/DHLWarning";
import util from "@/util/util";

export default {
  name: "OrderDispatch",
  components: {ArticleTable, DHLWarning},
  data() {
    return {
      trackingNumber: "",
      valid: false,
      entryRules: [
        v => !!v || "Required",
        v => {
          if (v && this.shippingInformation) {
            let trackingNumberFound = false
            for (const i in this.shippingInformation) {
              const shippingInfo = this.shippingInformation[i]
              if (v.includes(shippingInfo.shippingId)) {
                trackingNumberFound = true
                break
              }
            }
            return trackingNumberFound || "Ungültige TrackingNr"
          } else return true
        }
      ],
      validatedTrackings: []
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderDispatchComplete() {
      let isComplete = true;
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i]
        isComplete &= orderItem.quantity === orderItem.dispatchedQuantity
      }
      return isComplete
    },
    allShippingInfosCorrectlyScanned() {
      for (const i in this.order.shipmentDetails) {
        const shippingInfo = this.order.shipmentDetails[i]
        if (shippingInfo.shipmentType === 0) {
          if (!this.validatedTrackings.includes(shippingInfo.shippingId)) {
            return false;
          }
        }
      }
      return true
    },
    fullName() {
      let result = ""

      const shippingAddress = this.order.shippingAddress;
      if (shippingAddress.firstName !== undefined && shippingAddress.firstName) {
        result += shippingAddress.firstName
      }
      if (shippingAddress.lastName !== undefined && shippingAddress.lastName) {
        if (result) result += " "
        result += shippingAddress.lastName
      }

      return result
    },
    streetAndHouseNumber() {
      let result = ""

      const shippingAddress = this.order.shippingAddress;
      if (shippingAddress.street) result += shippingAddress.street
      if (shippingAddress.houseNumber) {
        if (result) result += " "
        result += shippingAddress.houseNumber
      }

      return result
    },
    zipAndCity() {
      let result = ""

      const shippingAddress = this.order.shippingAddress;
      if (shippingAddress.zip) result += shippingAddress.zip
      if (shippingAddress.city) {
        if (result) result += " "
        result += shippingAddress.city
      }

      return result
    },
    shippingInformation() {
      const shipmentDetails = this.order.shipmentDetails;
      if (shipmentDetails !== undefined && shipmentDetails) {
        // shipmentType 0 is the regular one. shipmentType 1 is the return
        return shipmentDetails.filter(si => si.shipmentType === 0)
      }

      return null
    },
    shouldShowDHLWarning() {
      const order = this.$store.state.order;
      return order && util.shouldShowDHLWarning(order.shippingProviderProductName)
    }
  },
  methods: {
    trackingNumberEntered(trackingNumber) {
      if (!this.valid) {
        // enter was clicked with an invalid status
        console.log("invalid enter")
        return
      }
      console.log(trackingNumber)
      for (const i in this.shippingInformation) {
        const shippingInfo = this.shippingInformation[i]
        if (trackingNumber.includes(shippingInfo.shippingId)) {
          shippingInfo.validated = true
          this.validatedTrackings.push(shippingInfo.shippingId)
        }
      }

      if (this.allShippingInfosCorrectlyScanned) {
        this.trackingValidationComplete()
      }

      this.trackingNumber = ""
    },
    trackingValidationComplete() {
      const self = this
      const finishFunction = function () {
        self.$store.dispatch('showInfo', {text: "Erfolgreich versendet", time: 2000})
        self.$router.push("/dispatch")
      }
      this.$store.dispatch('confirmDispatch')
          .then(function () {
            const order = self.$store.state.order;
            if (order.shippingProviderProductName !== "DHL Paket") {
              self.$store.dispatch("showInfoConfirm", {
                text: order.shippingProviderProductName,
                buttonText: "Ok",
                callback: finishFunction
              })
            } else {
              finishFunction()
            }
          })
    },
    requestAdditionalLabel() {
      const self = this
      this.$store.dispatch('showInfoConfirm', {
        text: "Möchtest du wirklich ein zusätzliches Versandlabel erstellen?",
        buttonText: "JA",
        cancelButtonText: "Abbrechen",
        cancelButtonColor: "red darken",
        callback: function () {
          self.$store.dispatch('requestAdditionalLabel')
              .then(function () {
                self.$store.dispatch('showInfo', {text: "Zusätzliches Versandlabel erstellt", time: 1000})
              })
        }
      })
    },
    noop() {

    }
  },
  mounted() {
    if (!this.$store.state.order.billBeeOrderId && this.$route.params['billBeeOrderId'] !== "undefined") {
      this.$store.dispatch("loadOrder", this.$route.params['billBeeOrderId'])
    }

    this.$refs["trackingNumber"].focus();
  }
}
</script>

<style scoped>

</style>