import Vue from 'vue'
import VueRouter from "vue-router";
import DispatchHome from "@/components/DispatchHome";
import OrderNumberEntry from "@/components/OrderNumberEntry";
import ArticleScan from "@/components/ArticleScan";
import DispatchConfirmation from "@/components/DispatchConfirmation";
import LoginHome from "@/components/LoginHome";
import axios from "@/plugins/axios";


Vue.use(VueRouter);

const routes = [
    { path: '/home', component: DispatchHome },
    { path: '/login', component: LoginHome },
    { path: '/dispatch', component: OrderNumberEntry },
    { path: '/dispatch/:billBeeOrderId', component: ArticleScan },
    { path: '/dispatch/:billBeeOrderId/confirm', component: DispatchConfirmation },
    { path: '/', redirect: '/home' }
];

const router =  new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
});

router.beforeEach(function (to, from, next) {
    if (to.path !== '/login') {
        axios
            .get("/api/login/loggedIn", {withCredentials: true})
            .then(() => next())
            .catch(() => next({path: '/login'}))
    } else next()
})

export default router;