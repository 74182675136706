<template>
  <v-main class="mt-10 mr-5">
    <v-row class="ml-5">
      <div class="text-h4" v-text="order.orderNumber"></div>
    </v-row>
    <v-row class="ml-5">
      <v-chip small color="primary">{{ order.sellingPlatform }}</v-chip>
    </v-row>

    <ArticleTable/>

    <v-row class="ml-3 mt-3">
      <v-col v-if="!orderDispatchComplete" xs="12" sm="6" md="4" lg="3" xl="3">
        <v-card>
          <v-card-title>
            <div class="primary--text">Artikel scannen</div>
          </v-card-title>
          <v-row class="ml-2 mr-2 mt-1">
            <v-col>
              <v-form ref="articleNumberEntry" v-model="valid" v-on:submit.prevent="noop">
                <v-text-field
                    v-model="articleNumber"
                    ref="articleNumber"
                    clearable
                    dense
                    outlined
                    inputmode="numeric"
                    :label="'EAN'"
                    :rules="entryRules"
                    @keyup.enter="articleEntered(articleNumber)"></v-text-field>
              </v-form>
            </v-col>

            <v-col>
              <v-btn color="secondary" tile :disabled="!valid" block large
                     @click="articleEntered(articleNumber)">Suchen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-if="orderDispatchComplete" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
        <v-container fill-height>
          <v-btn color="primary" x-large :height="75" block @click="confirmDispatch">
                        <span class="ma-2 text-wrap"
                              style="width: min-content; margin: auto;">Bestellung versenden</span>
          </v-btn>
        </v-container>
      </v-col>

      <v-col v-if="shouldShowDHLWarning" cols="12" xs="12" sm="6" md="8" lg="5" xl="5">
        <DHLWarning/>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <div class="pl-5 text-body-1">Ausgewählter Label-Drucker: {{ printerName }}</div>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import ArticleTable from "@/components/ArticleTable";
import articleLocator from "@/util/articleLocator";
import DHLWarning from "@/components/DHLWarning"
import util from "@/util/util";

export default {
  name: "OrderDispatch",
  components: {ArticleTable, DHLWarning},
  data() {
    return {
      articleNumber: "",
      valid: false,
      entryRules: [
        v => !!v || "Required",
      ]
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    printerName() {
      return this.$store.state.printerName
    },
    orderItems() {
      let result = []
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];
        result.push({
          quantity: orderItem.quantity,
          ean: orderItem.product.ean,
          title: orderItem.product.title,
          status: "x",
          dispatchedQuantity: orderItem.dispatchedQuantity
        })
      }
      return result
    },
    orderDispatchComplete() {
      let isComplete = true;
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i]
        isComplete &= orderItem.quantity === orderItem.dispatchedQuantity
      }
      return isComplete
    },
    shouldShowDHLWarning() {
      const order = this.$store.state.order
      return order
          && order.shippingProviderProductName
          && util.shouldShowDHLWarning(order.shippingProviderProductName)
    },
    totalQuantity() {
      let totalAmount = 0;
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i]
        totalAmount += orderItem.quantity
      }
      return totalAmount
    }
  },
  methods: {
    resetOrder() {
      console.log("resetOrder")
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];
        // reset all dispatched items
        orderItem.dispatchedQuantity = 0
      }
    },
    articleEntered(articleNumber) {
      console.log(articleNumber)
      const paddedArticleNumber = this.padEAN(articleNumber)
      let locatedArticle = articleLocator.locateArticle(this.$store.state.order.orderItems, paddedArticleNumber);

      if (locatedArticle.code === articleLocator.results.ArticleFound) {
        locatedArticle.item.dispatchedQuantity += 1
      } else {
        this.$store.dispatch('showErrorConfirm', {
          text: "Artikel gehört nicht zur Bestellung. Bitte Artikel sofort zurücklegen.",
          buttonText: "Ja, ich habe den Artikel von meinem Pack-Tisch entfernt",
          callback: this.resetOrder
        })
      }

      this.articleNumber = ""
      this.$refs["articleNumber"].focus();

      if (this.orderDispatchComplete && this.totalQuantity > 1) {
        this.$store.dispatch("showInfoConfirm",
            {
              text: "<span style='font-size: 300px;'>" + this.totalQuantity + "</span></b>",
              buttonText: "Passt, ich habe noch einmal nachgezählt",
              callback: this.confirmDispatch,
              cancelButtonText: "Passt leider nicht",
              cancelButtonColor: "red darken",
              cancelButtonCallback: this.resetOrder
            })
      }
    },
    padEAN(ean) {
      let result = ean;
      if (ean.length < 8) {
        result = this.padToLength(ean, 8);
      } else if (ean.length > 8 && ean.length < 13) {
        result = this.padToLength(ean, 13);
      }
      return result;
    },
    padToLength(ean, length) {
      let result = ean;
      while (result.length < length) {
        result = "0" + result;
      }
      return result;
    },
    confirmDispatch() {
      const self = this
      this.$store
          .dispatch('createLabel')
          .then(function () {
            const order = self.$store.state.order
            if (order.sellingPlatformId === 94597) {
              self.$store.dispatch("showInfoConfirm", {
                text: "Bitte Retoure-Label vernichten",
                buttonText: "Ok",
                callback: function () {
                  self.$router.push("/dispatch/" + self.$store.state.order.billBeeOrderId + "/confirm")
                }
              })
            } else {
              self.$router.push("/dispatch/" + self.$store.state.order.billBeeOrderId + "/confirm")
            }
          })
          .catch(function () {
            self.$store.dispatch('showErrorConfirm', {
              text: "Versandlabel konnte leider nicht erstellt werden. Bitte Ware und Dokument zusammen beiseite legen.",
              buttonText: "OK",
              callback: function () {
                self.$router.push("/")
              }
            })
          })
    },
    noop() {

    }
  },
  mounted() {
    if (!this.$store.state.order.billBeeOrderId && this.$route.params['billBeeOrderId'] !== "undefined") {
      this.$store.dispatch("loadOrder", this.$route.params['billBeeOrderId'])
    }

    let printerName = localStorage.getItem("lang.dispatch.printerName");
    if (printerName) {
      this.$store.commit("setPrinterName", printerName)
    } else {
      this.$router.push("/dispatch")
    }

    if (this.$refs["articleNumber"]) this.$refs["articleNumber"].focus();
  }
}
</script>

<style scoped>
alert-icon {
  height: 100px;
}
</style>